<template>
  <!-- eslint-disable max-len -->
  <section class="Tournaments">
    <div v-if="this.getLocale !== 'dk'" class="Title Title--type-h2 Tournaments-Title">
      {{ $t('homepage.tournaments.title') }} <br/>
      {{ $t('prepositions.in') }} <span class="Colored">{{ $t('title') }}</span>
    </div>
    <div class="Tournaments-Slider">
      <VueSlider v-bind="options" :willForceUpdate="getLocale">
        <div v-for="item in localeSlides" :key="$t(item.title)" class="Tournaments-Item"
             :class="{'Tournaments-Item--de': $i18n.locale === 'de' || $i18n.locale === 'no'|| $i18n.locale === 'fi'}"
        >
          <picture class="Tournaments-Image">
            <source
              v-if="item.image460"
              media="(max-width: 459px)" :srcset="require(`@/assets/img/${item.image460}`)"
            >
            <source
              v-if="item.image590"
              media="(max-width: 590px)" :srcset="require(`@/assets/img/${item.image590}`)"
            >
            <source
              v-if="item.image768"
              media="(max-width: 960px)" :srcset="require(`@/assets/img/${item.image768}`)"
            >
            <img :srcset="require(`@/assets/img/${item.image1248}`)" :alt="item.title" loading="lazy">
          </picture>
          <div class="Tournaments-Content"
               :class="{'Tournaments-Content--de': $i18n.locale === 'de' || $i18n.locale === 'no' || $i18n.locale === 'fi'}"
          >
            <div class="Title Tournaments-Name">
              {{ $t(item.title) }}
            </div>
            <div
              v-if="$t(item.bonus)"
              class="Tournaments-Bonus"
            >
              <span v-html="$t(item.bonus)"></span>
            </div>
            <div class="Tournaments-Text" v-if="$t(item.text)">
              <span v-html="$t(item.text)"></span>
            </div>
            <Counter
              v-if="item.tournament"
              class="Tournaments-Counter"
              :enddate="enddate"
            />
            <div class="Tournaments-Footer">
              <button class="Btn Btn--color Tournaments-Btn" @click="openRegistration()">
                {{ $t('buttons.bonus') }}
              </button>
            </div>
          </div>
        </div>
      </VueSlider>
    </div>
  </section>
</template>

<script>
import Counter from '@/components/Counter.vue';
import VueSlider from '@/components/Slider.vue';
import auth from '@/mixins/auth';

export default {
  name: 'Tournaments',
  components: {
    Counter,
    VueSlider,
  },
  mixins: [auth],
  data() {
    return {
      options: {
        items: 2,
        loop: false,
        dots: true,
        responsive: {
          0: {
            items: 1,
          },
          768: {
            items: 2,
          },
        },
      },
      slides: [
        {
          image460: 'tournaments_460.png',
          image590: 'tournaments_590.png',
          image768: 'tournaments_768.png',
          image960: 'tournaments_960.png',
          image1248: 'tournaments_1248.png',
          title: 'homepage.tournaments.banner1.title',
          bonus: 'homepage.tournaments.banner1.bonus',
          tournament: true,
        },
        {
          image460: 'bonus_590.png',
          image590: 'bonus_590.png',
          image768: 'bonus_768.png',
          image960: 'bonus_960.png',
          image1248: 'bonus_1248.png',
          title: 'homepage.tournaments.banner2.title',
          bonus: 'homepage.tournaments.banner2.bonus',
          text: 'homepage.tournaments.banner2.text',
        },
      ],
      enddate: new Date(),
    };
  },
  computed: {
    localeSlides() {
      if (this.getLocale === 'dk') {
        return [{
          image460: 'bonus_590.png',
          image590: 'bonus_590.png',
          image768: 'bonus_768.png',
          image960: 'bonus_960.png',
          image1248: 'bonus_1248.png',
          title: 'homepage.tournaments.banner2.title',
          bonus: 'homepage.tournaments.banner2.bonus',
          text: 'homepage.tournaments.banner2.text',
        }];
      } return this.slides;
    },
    getLocale() {
      return this.$i18n.locale;
    },
  },
  mounted() {
    this.enddate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
  },
};
</script>

<style lang="scss">
.Tournaments {
  margin-bottom: 26px;

  @media(min-width: $screen-l) {
    margin-bottom: 45px;
  }

  @media(min-width: $screen-xl) {
    margin-bottom: 60px;
  }

  &-Title {
    margin-bottom: 10px;
    text-align: center;

    @media(min-width: $screen-s) {
      margin-bottom: 30px;
      text-align: left;
    }

    @media(min-width: $screen-xl) {
      margin-bottom: 50px;
    }
  }

  &-Item {
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    @media(min-width: $screen-l) {
      height: 282px;
    }
  }

  &-Content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    padding-top: 193px / 320px * 100%;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;

    @media(min-width: $screen-xs) {
      position: absolute;
      top: 0;
      left: 0;
      align-items: flex-start;
      max-width: 60%;
      padding-top: 40px / 460px * 100%;
      padding-left: 0;
      padding-right: 0;
      text-align: left;
    }

    @media(min-width: $screen-s) {
      padding-top: 130px / 768px * 100%;
    }

    @media(min-width: $screen-m) {
      max-width: 65%;
      height: 264px;
      padding-top: 24px / 768px * 100%;
    }

    @media(min-width: $screen-l) {
      left: 30px;
      max-width: 55%;
      height: 278px;
      padding-top: 24px / 960px * 100%;
    }

    @media(min-width: $screen-xl) {
      max-width: 50%;
      height: 280px;
    }
  }

  &-Image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;

    @media(min-width: $screen-xs) {
      position: relative;
    }

    img {
      width: 100%;
    }
  }

  &-Name {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 800;

    @media(min-width: $screen-l) {
      margin-bottom: 10px;
      font-size: 20px;
    }

    @media(min-width: $screen-xl) {
      font-size: 28px;
    }
  }

  &-Bonus {
    margin-bottom: 3px;
    font-size: 20px;
    font-weight: 700;
    color: var(--color-main2);
    text-transform: uppercase;

    @media(min-width: $screen-m) {
      font-size: 14px;
    }

    @media(min-width: $screen-l) {
      font-size: 16px;
    }
  }

  &-Counter {
    margin-top: 16px;
    margin-bottom: 22px;

    @media(min-width: $screen-m) {
      margin-top: 12px;
      margin-bottom: 30px;
    }

    @media(min-width: $screen-l) {
      margin-top: 20px;
    }

    @media(min-width: $screen-xl) {
      margin-top: 30px;
      margin-bottom: 22px;
    }
  }

  &-Text {
    margin-bottom: 14px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.66;
    color: var(--color-faded);

    @media(min-width: $screen-l) {
      margin-bottom: 22px;
    }
  }

  &-Footer {
    display: flex;
    align-items: center;

    @media(min-width: $screen-m) {
      margin-top: auto;
    }
  }

  &-Btn {
    margin-right: 22px;
    padding: 14px 20px;

    @media(min-width: $screen-l) {
      padding: 17px 30px;
    }
  }

  &-Link {
    font-size: 16px;
    font-weight: 300;
    color: var(--color-main1);
    text-transform: none;
  }

  .v_slider__dots {
    @media(min-width: $screen-m) {
      display: none;
    }
  }
}

.Tournaments-Item--de {
  @media(min-width: $screen-xs) {
    height: 432px;
  }

  @media(min-width: $screen-l) {
    height: 382px;
  }
}

.Tournaments-Content--de {

  @media(min-width: $screen-m) {
    max-width: 65%;
    height: 303px;
  }

  @media(min-width: $screen-l) {
    max-width: 55%;
    height: 353px;
  }

  @media(min-width: $screen-xl) {
    max-width: 50%;
    height: 367px;
  }
}
</style>
